<template>
  <div
    v-if="$canAndRulesPass(procedure, 'compensations.procedure_tree_see_in_menu')"
    v-loading="loading"
  >

    <page-heading
      :heading="procedure.name"
    >
      <span slot="help"></span>
      <div slot="right"></div>
    </page-heading>

    <div
      v-if="!$canAndRulesPass(user, 'compensations.procedure_tree_see_all')"
      style="margin: 30px 0"
    >
      <i class="fas fa-info-circle"></i>
      Нажмите на ФИО Руководителя для просмотра годового бонуса его подчиненных.
    </div>

    <el-tree
      class="procedure-users-tree"
      empty-text="Нет пользователей"
      :load="loadTreeNode"
      lazy
      ref="procedureUsersTree"
      node-key="id"
      :expand-on-click-node="false"
      v-loading="loadingRootUser"
      @node-click="userClicked"
    >
      <div class="procedure-users-tree-node" slot-scope="{ node, data }">
        <div class="procedure-users-tree-node-name">
          <div class="procedure-users-tree-node-name-position">{{ data.user.position }}</div>
          <div class="procedure-users-tree-node-name-full-name">{{ data.user.full_name }}</div>
        </div>
        <div class="procedure-users-tree-node-status">
          <div v-if="procedure.type === 'year_bonus'">
            <el-tag v-if="data.status === 2" type="success">Ознакомлен</el-tag>
            <el-tag v-else type="danger">Не ознакомлен</el-tag>
          </div>
          <div v-if="procedure.type === 'raise_rss' || procedure.type === 'raise_workers'">
            <el-tag v-if="data.status === 2" type="danger">{{$companyConfiguration.settings.getEnum('compensation_procedure_rss_agree_statuses')[data.status]}}</el-tag>
            <el-tag v-if="data.status === 1" type="info">{{$companyConfiguration.settings.getEnum('compensation_procedure_rss_agree_statuses')[data.status]}}</el-tag>
            <el-tag v-if="data.status === 3" >{{$companyConfiguration.settings.getEnum('compensation_procedure_rss_agree_statuses')[data.status]}}</el-tag>
            <el-tag v-if="data.status === 4" type="success">{{$companyConfiguration.settings.getEnum('compensation_procedure_rss_agree_statuses')[data.status]}}</el-tag>
          </div>
        </div>
      </div>
    </el-tree>


  </div>
</template>
<script>
import requestSender from "@/api/base/requestSender";
import PageHeading from "@/components/PageHeading";
import {mapGetters} from "vuex";

export default {
  name: 'CompensationProcedureTree',
  components: {
    PageHeading,
  },
  mixins: [],
  computed: {
    ...mapGetters(['user']),
  },
  watch: {},
  data() {
    return {
      loading: false,
      loadingRootUser: false,

      procedure: {},

    }
  },
  mounted() {
    this.loadProcedure();
  },
  methods: {
    loadProcedure() {
      this.loading = true;

      requestSender('get', 'compensation-procedure/get', {
        id: parseInt(this.$route.params.id),
        expand: ['meta', 'permissions'].join(),
      })
        .then(data => {
          this.procedure = data.procedure;
        })
        .finally(() => {
          this.loading = false;
        });
    },


    loadTreeNode(node, resolve) {
      this.loadingRootUser = !node.data;
      requestSender('get', 'compensation-procedure/get-users-tree', {
        procedure_id: this.$route.params.id,
        parent_user_id: node.data ? node.data.user_id : null,
        expand: ['user'].join(),
      })
        .then(data => {
          this.loadingRootUser = false;
          return resolve(data.procedure_owners)
        })
    },
    userClicked(node, nodeOfTree, treeNode) {
      if( this.$canAndRulesPass(this.user, 'compensations.procedure_tree_see_all') ){
        return;
      }
      const { href } = this.$router.resolve({ name: 'CompensationProcedureView', params: { procedure_id: this.$route.params.id, owner_id:node.user_id } } );
      window.open(href, '_blank');
    },

  }
}
</script>

<style lang="scss">
.procedure-users-tree{

  &-node{
    display: flex;
    justify-content: space-between;
    gap: 40px;

    &-name{
      margin-left: 10px;

      &-position{
        font-size: .8rem;
        color: gray;
      }
      &-full-name{
        font-weight: bold;
      }
    }
  }

  & .el-tree-node__content{
    height: 50px !important;

    & .el-tree-node__expand-icon{
      font-size: 25px;
    }
  }

  //&-node{
  //  height:100px;
  //}
}
</style>